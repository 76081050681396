import {
  OPEN_GYM_MODAL,
  RESET_GYM,
  ADD_GYM,
  ADD_GYM_SUCCESS,
  ADD_GYM_ERROR,
  EDIT_GYM_SUCCESS,
  EDIT_GYM_ERROR,
  UPDATE_GYM,
  UPDATE_GYM_SUCCESS,
  UPDATE_GYM_ERROR,
  DELETE_GYM,
  DELETE_GYM_SUCCESS,
  DELETE_GYM_ERROR,
  FETCH_GYMS,
  FETCH_GYMS_SUCCESS,
  FETCH_GYMS_ERROR,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  FETCH_COUNTRIES_SUCCESS,
  GET_OSS_TOKEN_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';
import { imageServerURL } from '../../util/common';
import config from '../../config.json';

const OSS = require('ali-oss');

const default_gym = {
  _id: null,
  email: null,
  phone: null,
  contacts: [],
  locations: [],
  img: [],
  note: null,
  enable: false
};

const INIT_STATE = {
  pending: false,
  edit: false,
  gym_modal_open: false,
  gym: default_gym,
  gym_list: [],
  countries: [],
  oss_cred: null,
  OSSclient: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case RESET_GYM:
      return { ...state, pending: false, gym: { ...default_gym }, edit: false, gym_modal_open: false }

    case OPEN_GYM_MODAL:
      return { ...state, gym_modal_open: payload }

    case FETCH_GYMS:
    case ADD_GYM:
    case UPDATE_LOCATION:
      return { ...state, pending: true }

    case ADD_GYM_SUCCESS:
      message.success('New gym added successfully');
      return { ...state, gym_list: payload.map(el => ({ key: el._id, ...el })), pending: false, gym_modal_open: false }

    case FETCH_GYMS_ERROR:
    case ADD_GYM_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_GYMS_SUCCESS:
      return { ...state, gym_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case EDIT_GYM_SUCCESS:
      console.log(payload);
      item = state.gym_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          gym: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          gym_modal_open: true
        }
      } else {
        return {
          ...state,
          gym_list: state.gym_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          gym: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          gym_modal_open: true
        }
      }

    case UPDATE_GYM:
      console.log('Update gym Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_GYM_SUCCESS:
      message.success('Gym updated successfully');
      return { ...state, gym_list: payload.map(el => ({ key: el._id, ...el })), gym: { ...default_gym }, pending: false, gym_modal_open: false, edit: false }

    case DELETE_GYM:
      console.log('Delete gym Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_GYM_SUCCESS:
      message.success('Gym deleted successfully');
      return { ...state, gym_list: payload.map(el => ({ key: el._id, ...el })), gym: { ...default_gym }, pending: false, gym_modal_open: false, edit: false }

    case UPDATE_LOCATION_SUCCESS:
      console.log(payload)
      message.success('Location updated successfully');
      return { ...state, gym: { ...state.gym, locations: payload }, pending: false }

    case DELETE_LOCATION_SUCCESS:
      message.success('Location deleted successfully');
      return { ...state, gym: { ...state.gym, locations: payload }, pending: false }

    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: payload, pending: false }
  
    case EDIT_GYM_ERROR:
    case UPDATE_GYM_ERROR:
    case DELETE_GYM_ERROR:
    case UPDATE_LOCATION_ERROR:
    case DELETE_LOCATION_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case GET_OSS_TOKEN_SUCCESS:
      let client = new OSS({
        region: config.oss.region,
        bucket: config.oss.bucket,
        accessKeyId: payload.AccessKeyId,
        accessKeySecret: payload.AccessKeySecret,
        stsToken: payload.SecurityToken
      });
      return { ...state, oss_cred: payload, OSSclient: client };

    default:
      return state;
  }
}
