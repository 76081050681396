import axios from 'axios';
import firebase from "firebase";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import thunk from 'redux-thunk';
import { all } from "redux-saga/effects";
import config from '../../config.json';

// Reducers
import Settings from "../reducers/Settings";
import Auth from "../reducers/Auth";
import Banners from "../reducers/Banners";
import Coupons from "../reducers/Coupons";
import Common from "../reducers/Common";
import Users from "../reducers/Users";
import Members from "../reducers/Members";
import Participants from "../reducers/Participants";
import Merchandise from "../reducers/Merchandise";
import Pass from "../reducers/Pass";
import Workshop from "../reducers/Workshop";
import Orders from "../reducers/Orders";
import Ticket from "../reducers/Ticket";
import Sales from "../reducers/Sales";
import Gyms from "../reducers/Gyms";
import Attendance from "../reducers/Attendance";

// Sagas
import authSagas from "../sagas/Auth";
import bannerSagas from "../sagas/Banners";
import couponSagas from "../sagas/Coupons";
import userSagas from "../sagas/Users";
import memberSagas from "../sagas/Members";
import participantSagas from "../sagas/Participants";
import merchandiseSagas from "../sagas/Merchandise";
import passSagas from "../sagas/Pass";
import workshopSagas from "../sagas/Workshop";
import orderSagas from "../sagas/Orders";
import ticketSagas from "../sagas/Ticket";
import saleSagas from "../sagas/Sales";
import gymSagas from "../sagas/Gyms";
import attendanceSagas from "../sagas/Attendance";

axios.defaults.baseURL = config.axios.baseURL;
axios.defaults.withCredentials = config.axios.withCredentials;
axios.defaults.headers.common['Authorization'] = config.axios.headers.common['Authorization'];
axios.defaults.headers.post['Content-Type'] = config.axios.headers.post['Content-Type'];

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    console.log(err.response);
    if (err.response.status === 403) {
      let user = firebase.auth().currentUser;
      console.log('Logging out!');
      console.log(user);
      firebase.auth().signOut();
    }
    return Promise.reject(err);
  }
);

const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

// Combining Sagas
function* rootSaga(getState) {
  yield all([
    authSagas(),
    bannerSagas(),
    couponSagas(),
    userSagas(),
    memberSagas(),
    participantSagas(),
    merchandiseSagas(),
    passSagas(),
    workshopSagas(),
    orderSagas(),
    ticketSagas(),
    saleSagas(),
    gymSagas(),
    attendanceSagas()
  ]);
}

// Combining Reducers
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  banners: Banners,
  coupons: Coupons,
  common: Common,
  users: Users,
  members: Members,
  participants: Participants,
  merchandises: Merchandise,
  passes: Pass,
  workshops: Workshop,
  orders: Orders,
  tickets: Ticket,
  sales: Sales,
  gyms: Gyms,
  attendance: Attendance
});

// Configure Store
export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
