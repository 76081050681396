import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Promotion = ({match}) => (
  <Switch>
    <Route path={`${match.url}/banner`} component={asyncComponent(() => import('../../components/Promotion/Banner'))}/>
    <Route path={`${match.url}/coupon`} component={asyncComponent(() => import('../../components/Promotion/Coupon'))}/>
  </Switch>
);

export default Promotion;
