import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchOrdersSuccess, addOrderSuccess, addOrderError, cancelOrderSuccess, cancelOrderError, processOrderSuccess, processOrderError, shipOrderSuccess, shipOrderError, refundOrderSuccess, refundOrderError, addOrderNoteSuccess, addOrderNoteError, exportOrderReportSuccess, exportOrderReportError, exportDailyReportSuccess, exportDailyReportError, resendInvoiceSuccess, resendInvoiceError, changePaymentMethodsSuccess, changePaymentMethodsError, emailUpdateSuccess, emailUpdateError, transIDUpdateSuccess, transIDUpdateError } from '../actions/Orders';
import fileDownload from 'js-file-download';

// function* addOrderRequest({ order }) {
//   console.log('Add Order called');
//   try {
//     const resp = yield call(axios.post, '/v1/admin/add_order', order);
//     yield put(addOrderSuccess(resp.data));
//   } catch (error) {
//     console.log(error.response);
//     yield put(addOrderError(error.response));
//   }
// }

// function* editOrderRequest({ payload }) {
//   try {
//     const resp = yield call(axios.post, '/v1/admin/get_order', payload);
//     yield put(editOrderSuccess(resp.data));
//   } catch (error) {
//     console.log(error.response);
//     yield put(editOrderError(error.response ? error.response.data : 'Server not responding'));
//   }
// }

function* processOrderRequest({ payload }) {
  console.log('Process Order called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/process_order', payload);
    console.log(resp);
    yield put(processOrderSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(processOrderError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* shipOrderRequest({ payload }) {
  console.log('Ship Order called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/ship_order', payload);
    console.log(resp);
    yield put(shipOrderSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(shipOrderError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* cancelOrderRequest({ payload }) {
  console.log('Cancel Order called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/cancel_order', payload);
    console.log(resp);
    yield put(cancelOrderSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(cancelOrderError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* refundOrderRequest({ payload }) {
  console.log('Refund Order called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/refund_order', payload);
    console.log(resp);
    yield put(refundOrderSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(refundOrderError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchOrdersRequest({ payload }) {
  console.log(payload);
  let query = {}

  if (payload) {
    query = payload;
  }

  try {
    const resp = yield call(axios.post, '/v1/admin/orders', query);
    yield put(fetchOrdersSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* addOrderNoteRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/add_order_note', payload);
    yield put(addOrderNoteSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addOrderNoteError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportOrderReportRequest({ payload }) {
  console.log('Export Order Report called', payload);
  try {
    const resp = yield call(axios.get, '/v1/admin/export_order_report', {
      responseType: "arraybuffer",
      params: payload
    });
    console.log(resp);

    fileDownload(resp.data, payload.filename);

    yield put(exportOrderReportSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportOrderReportError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportDailyReportRequest({ payload }) {
  console.log('Export Daily Report called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/export_daily_report', payload);
    console.log(resp);
    yield put(exportDailyReportSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportDailyReportError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* resendInvoiceRequest({ payload }) {
  console.log('Resend Invoice called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/resend_invoice', { _id: payload });
    console.log(resp);
    yield put(resendInvoiceSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(resendInvoiceError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* changePaymentMethodsRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/change_payment_methods', payload);
    yield put(changePaymentMethodsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(changePaymentMethodsError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchOrders() {
  yield takeLatest(ActionTypes.FETCH_ORDERS, fetchOrdersRequest);
}

// export function* addOrder() {
//   yield takeLatest(ActionTypes.ADD_ORDER, addOrderRequest);
// }

// export function* editOrder() {
//   yield takeLatest(ActionTypes.EDIT_ORDER, editOrderRequest);
// }

export function* processOrder() {
  yield takeLatest(ActionTypes.PROCESS_ORDER, processOrderRequest);
}

export function* shipOrder() {
  yield takeLatest(ActionTypes.SHIP_ORDER, shipOrderRequest);
}

export function* cancelOrder() {
  yield takeLatest(ActionTypes.CANCEL_ORDER, cancelOrderRequest);
}

export function* refundOrder() {
  yield takeLatest(ActionTypes.REFUND_ORDER, refundOrderRequest);
}

export function* addOrderNote() {
  yield takeLatest(ActionTypes.ADD_ORDER_NOTE, addOrderNoteRequest);
}

export function* exportOrderReport() {
  yield takeLatest(ActionTypes.EXPORT_ORDER_REPORT, exportOrderReportRequest);
}

export function* exportDailyReport() {
  yield takeLatest(ActionTypes.EXPORT_DAILY_REPORT, exportDailyReportRequest);
}

export function* resendInvoice() {
  yield takeLatest(ActionTypes.RESEND_INVOICE, resendInvoiceRequest);
}

export function* changePaymentMethods() {
  yield takeLatest(ActionTypes.CHANGE_PAYMENT_METHODS, changePaymentMethodsRequest);
}


function* emailUpdateRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/update_order_customer_email', payload);
    yield put(emailUpdateSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(emailUpdateError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* emailUpdate() {
  yield takeLatest(ActionTypes.UPDATE_EMAIL, emailUpdateRequest);
}

function* transIDRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/process_order', payload);
    yield put(transIDUpdateSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(transIDUpdateError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* transID() {
  yield takeLatest(ActionTypes.UPDATE_TRANS_ID, transIDRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrders),
    fork(processOrder),
    fork(shipOrder),
    fork(cancelOrder),
    fork(refundOrder),
    fork(addOrderNote),
    fork(changePaymentMethods),
    fork(exportOrderReport),
    fork(exportDailyReport),
    fork(resendInvoice),
    fork(emailUpdate),
    fork(transID)
  ]);
}
