import React from "react";
import { Route, Switch } from "react-router-dom";

import Components from "./components/index";
import CustomViews from "./customViews/index";
import Extensions from "./extensions/index";
import ExtraComponents from "./extraComponents/index";
import InBuiltApps from "./inBuiltApps/index";
import SocialApps from "./socialApps/index";
import Main from "./main/index";
import Documents from "./documents/index";
import Users from "./users";
import Members from "./members";
import Participants from "./participants";
import Products from "./products";
import Promotion from "./promotion";
import Gallery from "./gallery";
import Orders from "./orders";
import Sales from "./sales";
import Gyms from "./gyms";
import Calendar from "./calendar";
import Attendance from "./attendance";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main} />
      <Route path={`${match.url}components`} component={Components} />
      <Route path={`${match.url}custom-views`} component={CustomViews} />
      <Route path={`${match.url}extensions`} component={Extensions} />
      <Route path={`${match.url}extra-components`} component={ExtraComponents} />
      <Route path={`${match.url}in-built-apps`} component={InBuiltApps} />
      <Route path={`${match.url}social-apps`} component={SocialApps} />
      <Route path={`${match.url}documents`} component={Documents} />
      <Route path={`${match.url}user`} component={Users} />
      <Route path={`${match.url}member`} component={Members} />
      <Route path={`${match.url}participant`} component={Participants} />
      <Route path={`${match.url}product`} component={Products} />
      <Route path={`${match.url}promotion`} component={Promotion} />
      <Route path={`${match.url}gallery`} component={Gallery} />
      <Route path={`${match.url}order`} component={Orders} />
      <Route path={`${match.url}sales`} component={Sales} />
      <Route path={`${match.url}gym`} component={Gyms} />
      <Route path={`${match.url}calendar`} component={Calendar} />
      <Route path={`${match.url}attendance`} component={Attendance} />
    </Switch>
  </div>
);

export default App;
