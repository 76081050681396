import {
  OPEN_GROUP_MODAL, FETCH_GROUPS, FETCH_GROUPS_SUCCESS, ADD_GROUP, ADD_GROUP_SUCCESS, ADD_GROUP_ERROR, UPDATE_GROUP, UPDATE_GROUP_SUCCESS, UPDATE_GROUP_ERROR, DELETE_GROUP, DELETE_GROUP_SUCCESS, DELETE_GROUP_ERROR, RESET_GROUP, EDIT_GROUP_SUCCESS, EDIT_GROUP_ERROR, GET_GROUP_SETUP_SUCCESS,
  OPEN_PASS_MODAL, FETCH_PASSES, FETCH_PASSES_SUCCESS, FETCH_PASS_CATEGORIES, FETCH_PASS_CATEGORIES_SUCCESS, ADD_PASS, ADD_PASS_SUCCESS, ADD_PASS_ERROR, UPDATE_PASS, UPDATE_PASS_SUCCESS, UPDATE_PASS_ERROR, DELETE_PASS, DELETE_PASS_SUCCESS, DELETE_PASS_ERROR, RESET_PASS, EDIT_PASS_SUCCESS, EDIT_PASS_ERROR, GET_PASS_SETUP_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';
import { imageServerURL } from '../../util/common';

const default_group = {
  _id: null,
  desc: null,
  criteria: null,
  img: null,
  enable: false
}

const default_pass = {
  _id: null,
  parent_sku: null,
  name: null,
  group: null,
  category: null,
  unit_price: null,
  min_qty: null,
  usage_limit: null,
  duration: null,
  special: null,
  gym: [],
  location: [],
  authority: null,
  desc: null,
  img: null,
  enable: false,
  allowable_day: [],
  shareable: false,
  exclude_public_holidays: false
}

const INIT_STATE = {
  pending: false,
  edit: false,
  group_modal_open: false,
  group: default_group,
  group_list: [],
  pass_modal_open: false,
  pass: default_pass,
  pass_list: [],
  active_passes: [],
  categories: []
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    // Groups
    case RESET_GROUP:
      return { ...state, pending: false, group: { ...default_group }, edit: false, group_modal_open: false }

    case OPEN_GROUP_MODAL:
      return { ...state, group_modal_open: payload }

    case FETCH_GROUPS:
      return { ...state, pending: true }

    case EDIT_GROUP_SUCCESS:
      console.log(payload);
      item = state.group_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          group: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          group_modal_open: true
        }
      } else {
        return {
          ...state,
          group_list: state.group_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          group: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          group_modal_open: true
        }
      }

    case EDIT_GROUP_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case ADD_GROUP:
      console.log(payload);
      return { ...state, pending: true }

    case ADD_GROUP_SUCCESS:
      message.success('New group added successfully');
      return { ...state, group_list: payload.map(el => ({ key: el._id, ...el })), group: { ...default_group }, pending: false, group_modal_open: false, edit: false }

    case ADD_GROUP_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_GROUP:
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_GROUP_SUCCESS:
      message.success('Pass updated successfully');
      return { ...state, group_list: payload.map(el => ({ key: el._id, ...el })), group: { ...default_group }, pending: false, group_modal_open: false, edit: false }

    case UPDATE_GROUP_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_GROUP:
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_GROUP_SUCCESS:
      message.success('Pass deleted successfully');
      return { ...state, group_list: payload.map(el => ({ key: el._id, ...el })), group: { ...default_group }, pending: false, group_modal_open: false, edit: false }

    case DELETE_GROUP_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_GROUPS_SUCCESS:
      return { ...state, group_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    // Passes
    case RESET_PASS:
      return { ...state, pending: false, pass: { ...default_pass }, edit: false, pass_modal_open: false }

    case OPEN_PASS_MODAL:
      return { ...state, pass_modal_open: payload }

    case FETCH_PASSES:
      return { ...state, pending: true }

    case EDIT_PASS_SUCCESS:
      console.log(payload);
      item = state.pass_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          pass: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          pass_modal_open: true
        }
      } else {
        return {
          ...state,
          pass_list: state.pass_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          pass: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          pass_modal_open: true
        }
      }

    case EDIT_PASS_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case ADD_PASS:
      console.log(payload);
      return { ...state, pending: true }

    case ADD_PASS_SUCCESS:
      message.success('New pass added successfully');
      return { ...state, pass_list: payload.map(el => ({ key: el._id, ...el })), pass: { ...default_pass }, pending: false, pass_modal_open: false, edit: false }

    case ADD_PASS_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_PASS:
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_PASS_SUCCESS:
      message.success('Pass updated successfully');
      return { ...state, pass_list: payload.map(el => ({ key: el._id, ...el })), pass: { ...default_pass }, pending: false, pass_modal_open: false, edit: false }

    case UPDATE_PASS_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_PASS:
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_PASS_SUCCESS:
      message.success('Pass deleted successfully');
      return { ...state, pass_list: payload.map(el => ({ key: el._id, ...el })), pass: { ...default_pass }, pending: false, pass_modal_open: false, edit: false }

    case DELETE_PASS_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_PASSES_SUCCESS:
      let pass_list = [];
      let active_passes = [];

      payload.forEach(el => {
        pass_list.push({ key: el._id, ...el });

        if (el.enable) {
          active_passes.push({ key: el._id, ...el });
        }
      })

      return { ...state, pass_list, active_passes, pending: false }

    case FETCH_PASS_CATEGORIES:
      return { ...state, pending: true }

    case FETCH_PASS_CATEGORIES_SUCCESS:
      return { ...state, categories: payload, pending: false }

    default:
      return state;
  }
}
